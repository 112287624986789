import styled from 'styled-components'

export const Column = styled.div`
  flex: 0 auto;
  width: 80%;
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.441em 0.882em;
  ${({ maxWidth }) => `max-width: ${maxWidth}`}
  }
`
